import React from 'react';
import css from './CommunityCarousel.module.css';
import backarrow from './carouselImages/backArrow.png';
import { NamedLink, ResponsiveImage } from '../../../components';
import { createSlug } from '../../../util/urlHelpers';
import { AVATAR_IMAGE_VARIANTS, AVATAR_SIZES_LARGE } from '../../../components/Avatar/Avatar';
import Slider from 'react-slick';
import useWindowSize from '../../../hooks/useWindowSize';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={css.arrowMain}>
      {className === 'slick-arrow slick-next' ? (
        <div onClick={onClick} className={css.nextArrowContainer}>
          <button className={css.nextBackBtn}>
            <img src={backarrow} alt={'back arrow'} style={{ width: '30px', height: 'auto' }} />
          </button>
        </div>
      ) : (
        <div onClick={onClick} className={css.prevArrowContainer}>
          <button className={css.btn}>
            <img src={backarrow} alt={'back arrow'} style={{ width: '30px', height: 'auto' }} />
          </button>
        </div>
      )}
    </div>
  );
}

const CommunityCarousel = ({ smallBusinesses, includedImages }) => {
  const { width } = useWindowSize();

  let visibleSlides = 1;

  if (width <= 425) {
    visibleSlides = 1.01;
  } else if (width <= 640) {
    visibleSlides = 2;
  } else {
    visibleSlides = 3;
  }
  const filteredSmallBusiness =
    smallBusinesses.length > 10 ? smallBusinesses.slice(0, 10) : smallBusinesses;
  const settings = {
    infinite: true,
    slidesToShow: visibleSlides,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
    className: 'sliderBlock',
  };

  return (
    <div className={css.sliderMainContainer} style={{ width: '100%', maxWidth: width + 'px' }}>
      <Slider {...settings}>
        {filteredSmallBusiness &&
          filteredSmallBusiness.length &&
          filteredSmallBusiness.map((seller, index) => {
            const { title = '' } = seller.attributes;
            const slug = createSlug(title);
            const imageData = includedImages.find(
              image => image.id.uuid === seller.relationships.profileImage.data.id.uuid
            );
            return (
              <div key={index} className={css.slideBorder}>
                <div className={css.slide}>
                  <NamedLink name="ProfilePage" params={{ id: seller?.id.uuid, slug }}>
                    <span className={css.profileLink}>VIEW STORE</span>
                    <ResponsiveImage
                      alt={'communityImage'}
                      image={imageData}
                      variants={AVATAR_IMAGE_VARIANTS}
                      sizes={AVATAR_SIZES_LARGE}
                    />
                    <div className={css.label}>
                      <div className={css.labelMain}>
                        <h3>
                          {seller.attributes.profile.displayName ||
                            seller.attributes.profile.publicData.storeName
                          }
                        </h3>
                        <p>{seller.attributes.profile.publicData.storeNeighborhood}</p>
                      </div>
                      <div className={css.arrowImg}>
                        <img src={backarrow} alt="Arrow" />
                      </div>
                    </div>
                  </NamedLink>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default CommunityCarousel;
